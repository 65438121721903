import styled from 'styled-components';
import { styles, StylesProps } from '@superhi/design/dist/cjs/components/forms/inputs/Base';
import { theme } from '@superhi/design';

type WrapperProps = {
  $isFocused?: boolean;
} & StylesProps;

export const Wrapper = styled.div<WrapperProps>`
  ${styles}

  display: flex;
  align-items: center;

  border-color: ${({ $isFocused }) => ($isFocused ? `${theme.colors.BLUE_50}!important` : '')};

  > * {
    width: 100%;
  }
`;
