import { StripeCardElementOptions } from '@stripe/stripe-js';
import { theme } from '@superhi/design';

export const CARD_ELEMENT_OPTIONS: StripeCardElementOptions = {
  hideIcon: false,
  style: {
    base: {
      fontSize: '16px',
      color: theme.colors.GREY_90,
      // padding: '20px',
      backgroundColor: theme.colors.WHITE,
      fontSmoothing: 'inherit',
      fontFamily: 'GT America',
      '::placeholder': {
        color: theme.colors.GREY_50,
      },
    },
    invalid: {
      color: theme.colors.RED_50,
    },
  },
};
