import { ApolloError } from '@apollo/client';
import { FormikHelpers } from 'formik';

export type RealGraphQLError = {
  message: string;
  details: string;
};

export const createErrorMap = (e: unknown, fieldNameMap: { [key: string]: string } = {}) => {
  if (e instanceof ApolloError) {
    return e.graphQLErrors.reduce((acc, err) => {
      const error = (err as unknown) as RealGraphQLError;
      const fieldName = error.details;

      return {
        ...acc,
        [fieldNameMap[fieldName] || fieldName]: err.message,
      };
    }, {});
  } else {
    return {};
  }
};

export const setErrorsFromApollo = <Values>(
  setFieldError: FormikHelpers<Values>['setFieldError'],
  e: unknown,
  fieldNameMap?: { [key: string]: string },
) => {
  if (e instanceof ApolloError) {
    let firstFieldName: string | undefined;

    e.graphQLErrors.forEach((err, i) => {
      const error = (err as unknown) as RealGraphQLError;
      const fieldName = error.details;

      if (i === 0) {
        firstFieldName = fieldName;
      }

      setFieldError(fieldNameMap ? fieldNameMap[fieldName] : fieldName || fieldName, err.message);
    });

    if (firstFieldName) {
      const el = document.querySelector(`[name="${firstFieldName}"]`)?.parentElement;

      if (el && el?.getBoundingClientRect().top < 0) {
        el.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }
};

export class SuperHiFormError extends Error {
  genericMessage?: string;
  fields?: {
    [name: string]: string;
  };

  static defaultErrorMessage = 'An error occurred. Please contact customer support.';

  constructor(config: {
    genericMessage?: typeof SuperHiFormError.prototype.genericMessage;
    fields?: typeof SuperHiFormError.prototype.fields;
  }) {
    super('An error occurred.');
    Object.setPrototypeOf(this, new.target.prototype);
    this.name = 'SuperHiFormError';
    this.fields = config.fields;
    this.genericMessage = config.genericMessage;
  }
}
