import React from 'react';
import { FormSelectInput } from '@superhi/design';
import countryList from 'country-list';

countryList.overwrite([
  {
    code: 'GB',
    name: 'United Kingdom',
  },
  {
    code: 'US',
    name: 'United States',
  },
]);

const COUNTRY_OPTIONS = countryList
  .getData()
  .sort((a, b) => {
    return a.name.localeCompare(b.name);
  })
  .map(country => ({
    value: country.code,
    display: country.name,
  }));

type Props = Omit<React.ComponentProps<typeof FormSelectInput>, 'options'>;

const FormSelectInputCountryCode: React.FC<Props> = props => (
  <FormSelectInput {...props} options={COUNTRY_OPTIONS} />
);

export default FormSelectInputCountryCode;
